import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    index: 0
};

export const MainMenuSlice = createSlice({
    name: 'mainMenu',
    initialState,
    reducers: {
        setMainMenuIndex: (state, action) => {
            state.index = action.payload;
        }
    }
});

export const { setMainMenuIndex } = MainMenuSlice.actions;
export default MainMenuSlice.reducer;