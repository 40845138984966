import { configureStore } from '@reduxjs/toolkit';
import appSettingsReducer from './appsettingsreducer';
import mainMenuReducer from './mainmenureducer';

const store = configureStore({
    reducer: {
        appSettingsInfo: appSettingsReducer,
        mainMenu: mainMenuReducer
    }
});
export default store;
