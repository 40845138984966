import CarouselImages from '../../components/Carousel';
import ProductService from './productservice';
import opacity16x9Img from '@/assets/images/16x9opacity.png';

function HomeContent() {
    let imageList = [
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/5Fs4DHspKp.mp4',
            title: '',
            content: ''
        },
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/pYTbNFKAxy%E6%96%B0%E9%94%A6%E5%8C%96%E6%9C%BA%E9%97%A8%E5%A4%B4(1).jpg?imageMogr2/format/webp/ignore-error/1',
            title: '',
            content: ''
        },
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/QMnW4RmtxD%E5%8E%82%E6%88%BF%E5%9B%BE%E7%89%87%EF%BC%881%EF%BC%89.jpg?imageMogr2/format/webp/ignore-error/1',
            title: '',
            content: ''
        },
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/FjkPhQJM2E%E5%8E%82%E6%88%BF%E5%9B%BE%E7%89%87%20(2).jpg?imageMogr2/format/webp/ignore-error/1',
            title: '',
            content: ''
        },
        {
            url: 'https://5322117680050072-1695723063214.cdn.site.joinf.com/5322117680050072/A83SDKsk2A.png?imageMogr2/format/webp/ignore-error/1',
            title: '',
            content: ''
        }
    ];

    function CorpIntroduce() {
        return (
            <div className="w-full">
                <div className="text-6xl flex flex-col items-center my-20">
                    <p>我们是高端能源设备制造商</p>
                    <div className="w-full h-10" />
                    <p>我们是综合能源服务提供商</p>
                </div>
                <div className="w-full flex justify-center items-center px-20">
                    <div className="w-1/3 text-2xl">
                        <p>
                            望挚（山东）透平科技有限公司成立于2021年01月28日，注册地位于山东省烟台市经济技术开发区北京南路8号东方工业园一期主办公楼4009，法定代表人为温韶凯。经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；气体压缩机械制造；泵及真空设备制造；汽轮机及辅机制造；制冷、空调设备制造；建筑工程用机械制造；风机、风扇制造；通用零部件制造；机械零件、零部件加工；普通机械设备安装服务；机械设备研发；专用设备修理；通用设备修理；电气设备修理；机械设备销售；有色金属合金销售；仪器仪表销售；包装服务；装卸搬运；绘图、计算及测量仪器制造（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：特种设备制造；检验检测服务；测绘服务（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）
                        </p>
                    </div>
                    <div className="w-20" />
                    <img
                        className="w-1/2"
                        src="https://site-1306369054.file.myqcloud.com/5322117680050072/c3BrkzFkEY%E4%BC%81%E4%B8%9A%E5%9B%BE.png?imageMogr2/format/webp/ignore-error/1"
                    />
                </div>
            </div>
        );
    }

    function ProductServiceContainer() {
        return (
            <div className="w-full">
                <div className="text-6xl flex flex-col items-center my-40">
                    <p>产品与服务</p>
                </div>
                <div className="relative" style={{ width: '100%', height: 0, paddingBottom: '40%' }}>
                    <div className="absolute px-20 w-full h-full">
                        <ProductService />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-fit flex flex-col justify-start items-center">
            <div className="relative" style={{ width: '100%', height: 0, paddingBottom: '56%' }}>
                <div className="absolute px-20 w-full h-full">
                    <CarouselImages images={imageList} autoPlay={false} />
                </div>
                {/* <img className="w-full" src={opacity16x9Img} />
                <CarouselImages images={imageList} autoPlay={false} /> */}
            </div>
            <CorpIntroduce />
            <ProductServiceContainer />
        </div>
    );
}

export default HomeContent;
