import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import gongAnImg from '@/assets/images/gongan.png';

function Tailer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className="w-full  text-2xl flex flex-col justify-start items-center">
            <div className="w-11/12 h-0.5 bg-gray-300 mt-2" />
            <div className="h-40 py-6 flex flex-col justify-between items-center">
                <p>Copyright 2024 aohuacloud.com All Rights Reserved 版权所有 望擎透平压缩技术有限公司</p>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2023021707号-1</a>
                <div className="flex items-center">
                    <img className="h-6 mx-2" src={gongAnImg} alt="" />
                    <a
                        target={'_blank'}
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11030102011646"
                    >
                        <p>京公网安备 11030102011646号</p>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default Tailer;
