import { WeekDays } from '@/shared/constants';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import config from '@/shared/appconfig';
import lodash, { size } from 'lodash';

function HorizonMenu({ menuItems, index, onMenuClicked }) {
    const [curIndex, setCurIndex] = useState(index);

    function onMenuClick(index) {
        setCurIndex(index);
        onMenuClicked(index);
    }

    function MenuItem({ name, index, isFocus, onClick }) {
        const [isHover, setIsHover] = useState(false);
        const handleMouseEnter = () => {
            setIsHover(true);
        };
        const handleMouseLeave = () => {
            setIsHover(false);
        };
        return (
            <div
                className="mx-16"
                style={{
                    fontSize: isHover ? '2.5rem' : '2.25rem',
                    fontWeight: isFocus ? 700 : 400
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
            >
                <p>{name}</p>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-row justify-start items-center text-4xl font-normal">
            {menuItems.map((menuName, menuIndex) => {
                return (
                    <MenuItem
                        key={`item${menuIndex}`}
                        name={menuName}
                        index={menuIndex}
                        isFocus={curIndex === menuIndex}
                        onClick={() => onMenuClick(menuIndex)}
                    />
                );
            })}
        </div>
    );
}
export default HorizonMenu;
