import AboutContent from './aboutcontent';
import Header from '@/components/Header';
import Tailer from '@/components/Tailer';

function About() {
    console.log('------------- on About() -------------------');
    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <AboutContent />
            <Tailer />
        </div>
    );
}

export default About;
