import { Routes, Route } from 'react-router-dom';
import Home from '@/pages/home';
import Launch from '@/pages/launch';
import About from '@/pages/about';
import Contact from '@/pages/contact';
import TypicalCase from '@/pages/typicalcase';
import Maintenance from '@/pages/maintenance';
import Product from '@/pages/product';

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Launch />}>
                    <Route path="home" element={<Home />}></Route>
                    <Route path="about" element={<About />}></Route>
                    <Route path="product" element={<Product />}></Route>
                    <Route path="typicalCase" element={<TypicalCase />}></Route>
                    <Route path="maintenance" element={<Maintenance />}></Route>
                    <Route path="contact" element={<Contact />}></Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
