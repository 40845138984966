import { useNavigate } from 'react-router-dom';
import HorizonMenu from '../HorizonMenu';
import logoImg from '@/assets/images/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { setMainMenuIndex } from '@/store/mainmenureducer';

function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const menuIndex = useSelector((state) => state.mainMenu?.index);

    function onMenuClicked(navigate, index) {
        dispatch(setMainMenuIndex(index));
        switch (index) {
            case 0:
                navigate('/home');
                break;
            case 1:
                navigate('/about');
                break;
            case 2:
                navigate('/product');
                break;
            case 3:
                navigate('/typicalCase');
                break;
            case 4:
                navigate('/maintenance');
                break;
            case 5:
                navigate('/contact');
                break;
            default:
                break;
        }
    }
    return (
        <div className="w-full  h-32 flex ">
            <img className="mx-20 my-3" src={logoImg} alt="" />
            <HorizonMenu
                menuItems={['首页', '关于我们', '产品', '典型案例', '维修', '联系我们']}
                index={menuIndex}
                onMenuClicked={(index) => {
                    onMenuClicked(navigate, index);
                }}
            />
        </div>
    );
}
export default Header;
