import CarouselImages from '../../components/Carousel';
function AboutContent() {
    let imageList = [
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/2eYiSkFn8a%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230630152842.jpg?imageMogr2/format/webp/format/webp/ignore-error/1',
            title: '',
            content: ''
        },
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/6s2hpdWtFM%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230703111113.jpg?imageMogr2/format/webp/format/webp/ignore-error/1',
            title: '',
            content: ''
        },
        {
            url: 'https://site-1306369054.file.myqcloud.com/5322117680050072/fk6hsjQwww%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230703111119.png?imageMogr2/format/webp/format/webp/ignore-error/1',
            title: '',
            content: ''
        }
    ];

    function CorpInfo() {
        return (
            <>
                <div
                    className="relative mt-20"
                    style={{ width: '80%', height: 0, paddingBottom: '30%' }}
                >
                    <div className="absolute w-full h-full">
                        <img
                            className="w-full h-full"
                            src="https://site-1306369054.file.myqcloud.com/5322117680050072/5KeciBJTQH%E6%81%92%E6%B3%B0%E8%89%BE%E6%99%AE%E9%97%A8%E5%A4%B4.jpg?imageMogr2/format/webp/format/webp/ignore-error/1"
                        />
                    </div>
                </div>
                <div
                    className="text-2xl my-20 indent-14 tracking-wide leading-relaxed"
                    style={{ width: '80%' }}
                >
                    <p>
                        3月9日，十四届全国人大二次会议在梅地亚中心新闻发布厅举行民生主题记者会。
                        教育部部长怀进鹏、人力资源和社会保障部部长王晓萍、住房和城乡建设部部长倪虹、国家疾病预防控制局局长王贺胜出席记者会。
                        今年全国两会期间共举办3场记者会，分别是经济主题、外交主题、民生主题记者会。王晓萍是记者会上唯一一位女部长。
                        王晓萍，女，汉族，1964年3月生，湖北黄冈人，二十届中央纪委委员。
                        王晓萍曾任国务院侨办副主任、党组成员。
                        2017年3月，她调任吉林省委常委、宣传部部长，2019年5月转任吉林省委常委、组织部部长。
                        2020年11月，王晓萍出任中央组织部副部长。
                        2022年10月，她出任中央纪委常委、国家监委委员、中央组织部副部长。
                        2022年12月，王晓萍转岗人社部，出任”一把手”。
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        2024年3月9日，王晓萍在记者会上回答了记者关于就业、技能人才培养等领域的问题。
                        王晓萍介绍，从今年的情况看，就业市场开局良好，企业开工复产、农民工返岗流动平稳有序，求职招聘活跃度上升。
                        “值得关注的是，人工智能、大数据等专业人才需求旺盛，康养护理、住宿餐饮、文化旅游等服务业需求走高，小微企业招聘需求明显增加，技术技能岗位需求快速上升。”
                        “做好就业工作具有许多有利的条件，我们对保持就业趋势持续稳定有信心、有底气。”王晓萍说：“与此同时，我们也看到，就业总量压力不减，结构性矛盾仍待破解，部分劳动者就业面临一些困难问题，稳就业还需付出更多努力。”
                        王晓萍介绍，今年将延续降低失业、工伤保险费率政策，落实和完善稳岗返还、专项贷款、就业和社保补贴等政策。
                        另外，今年高校毕业生将达到1179万人，人社部将强化促进青年就业政策举措，拓宽市场化渠道，稳定公共岗位规模，优化全周期就业创业指导服务。
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        在谈到技能人才工作时，王晓萍表示，适应新产业、新模式、新动能发展的数字型、创新型、复合型的高技能人才仍然稀缺，钳工、焊工、养老护理员等一线技工普遍短缺，人才培养需要更好地适应市场变化和产业的需求。
                        其中，要搭建技能人才成长的立交桥，逐步改变技能人才发展的独木桥、天花板的困境，增强年轻人学技能进工厂的意愿。
                        政知君在现场看到，王晓萍在回答记者提问时还专门介绍了社会保障卡。
                        她说，社会保障卡是政府民生服务的基础性载体，目前全国98%的人都有社保卡，9.7亿人还领用了电子社保卡，2023年电子社保卡全年服务是151亿人次。
                        社保卡服务涵盖了政务服务、就医购药、交通出行、文化旅游等民生领域。“我们将联合相关部门进一步做实做细社保卡居民的服务应用，推出更多的数字应用场景，持续深化省级一卡通，大力推进区域一卡通，逐步实现全国一卡通，为广大人民群众提供更便捷更广泛的社保卡服务。”
                        “总之我们将一件接着一件办，让人民人社服务有速度有温度，让老百姓更暖心更安心。”
                    </p>
                </div>
            </>
        );
    }

    return (
        <div className="w-full h-fit flex flex-col justify-start items-center">
            <div className="relative" style={{ width: '100%', height: 0, paddingBottom: '40%' }}>
                <div className="absolute px-20 w-full h-full">
                    <CarouselImages images={imageList} autoPlay={true} />
                </div>
                {/* <img className="w-full" src={opacity16x9Img} />
            <CarouselImages images={imageList} autoPlay={false} /> */}
            </div>
            <CorpInfo />
        </div>
    );
}

export default AboutContent;
