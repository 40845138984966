import HomeContent from './homecontent';
import Header from '@/components/Header';
import Tailer from '@/components/Tailer';

function Home() {
    console.log('------------- on Home() -------------------');
    return (
        <div className="w-full h-full flex flex-col ">
            <Header />
            <HomeContent />
            <Tailer />
        </div>
    );
}

export default Home;
