import { useState } from 'react';
import classNames from 'classnames';

export default function AutoZoomImage({ enableZoom, url }) {
    const [zoomIndex, setZoomIndex] = useState(0);

    return (
        <img
            className={classNames(
                'w-full h-full',
                enableZoom
                    ? zoomIndex === 0
                        ? 'animate-zoomIn scale-100'
                        : zoomIndex === 1
                        ? 'animate-zoomOut scale-110'
                        : ''
                    : ''
            )}
            src={url}
            onMouseEnter={() => {
                if (enableZoom) {
                    setZoomIndex(1);
                }
            }}
            onMouseLeave={() => {
                if (enableZoom) {
                    setZoomIndex(0);
                }
            }}
        />
    );
}
