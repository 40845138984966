import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import AutoZoomImage from './auto_zoom_image';

export default function CarouselImages({ images, autoPlay }) {
    const [playIndex, setPlayIndex] = useState({ preIndex: 0, current: 0, dir: 1 });
    const [timerId, setTimerId] = useState(0);
    const [isAutoPlay, setAutoPlay] = useState(autoPlay);

    let curIndex = playIndex.current;
    let preIndex = playIndex.preIndex;
    if (preIndex < 0) {
        preIndex = curIndex - 1;
    }
    if (preIndex < 0) {
        preIndex = images.length - 1;
    }

    //console.log(`CarouselImages images=${images} autoPlay=${autoPlay}`);

    // const fnRef = useRef();
    // fnRef.current = timerId;
    useEffect(() => {
        setTimer();
    }, [playIndex, isAutoPlay]);

    function setTimer() {
        if (isAutoPlay) {
            //console.log('--------setTimer-------');
            let tmpId = setTimeout(() => {
                let tmpIndex = playIndex.current + 1;
                if (tmpIndex >= images.length) {
                    tmpIndex = 0;
                }
                //console.log('---onTimer---');
                setTimerId(0);
                setPlayIndex({
                    preIndex: playIndex.current,
                    current: tmpIndex,
                    dir: 1
                });
            }, 5000);
            setTimerId(tmpId);
        }
    }

    function onClick(index) {
        if (images.length <= 0) {
            return;
        }
        let tmpIndex = 0;
        if (index === -1) {
            tmpIndex = playIndex.current - 1;
        } else {
            if (index === -2) {
                tmpIndex = playIndex.current + 1;
            } else {
                tmpIndex = index;
            }
        }
        if (tmpIndex < 0) {
            tmpIndex = images.length - 1;
        }
        if (tmpIndex >= images.length) {
            tmpIndex = 0;
        }
        if (playIndex.current !== tmpIndex) {
            if (timerId) {
                clearTimeout(timerId);
            }
            setPlayIndex({
                preIndex: playIndex.current,
                current: tmpIndex,
                dir: tmpIndex > playIndex.current ? 1 : 0
            });
        }
    }

    function GetImageVideoPlayer({ enableZoom, index, url }) {
        //console.log(`---GetImageVideoPlayer----${url} enableZoom=${enableZoom} index=${index}`);
        if (url && url.endsWith('.mp4')) {
            return (
                <video
                    className="w-full h-full object-fill"
                    autoPlay="autoplay"
                    src={url}
                    loop="loop"
                    muted="muted"
                    playsinline="playsinline"
                />
            );
        }
        return <AutoZoomImage enableZoom={enableZoom} url={url} />;
    }

    function GetImageContainer({ preIndex, curIndex, dir }) {
        //console.log(`---GetImageContainer---- dir=${dir} preIndex=${preIndex} curIndex=${curIndex}`);
        if (images.length <= 0) {
            return <></>;
        }
        if (preIndex === curIndex) {
            return (
                <>
                    <div className="absolute w-full h-full left-0 top-0" key={`pre_${curIndex}`}>
                        <GetImageVideoPlayer enableZoom={true} index={curIndex} url={images[curIndex].url} />
                        <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-end items-center text-2xl text-white pb-20 pointer-events-none">
                            <p>{images[curIndex].title}</p>
                            <p className="my-4 w-3/4 text-ellipsis whitespace-nowrap text-center overflow-hidden">
                                {images[curIndex].content}
                            </p>
                        </div>
                    </div>
                </>
            );
        } else {
            if (dir) {
                return (
                    <>
                        <div
                            className={classNames(
                                'absolute w-full h-full left-0 top-0',
                                curIndex === preIndex ? '' : 'animate-swiper'
                            )}
                            key={`cur_${preIndex}`}
                        >
                            <GetImageVideoPlayer
                                enableZoom={false}
                                index={preIndex}
                                url={images[preIndex].url}
                            />
                            <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-end items-center text-2xl text-white pb-20 pointer-events-none">
                                <p>{images[preIndex].title}</p>
                                <p className="shadow-2xl my-4 w-3/4 text-ellipsis whitespace-nowrap text-center overflow-hidden">
                                    {images[preIndex].content}
                                </p>
                            </div>
                        </div>
                        <div
                            className={classNames(
                                'absolute w-full h-full left-full top-0',
                                curIndex === preIndex ? '' : 'animate-swiper1x'
                            )}
                            // className="absolute w-full h-full left-full top-0 animate-swiper1x"
                            key={`pre_${curIndex}`}
                        >
                            <GetImageVideoPlayer
                                enableZoom={true}
                                index={curIndex}
                                url={images[curIndex].url}
                            />
                            <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-end items-center text-2xl text-white pb-20 pointer-events-none">
                                <p>{images[curIndex].title}</p>
                                <p className="my-4 w-3/4 text-ellipsis whitespace-nowrap text-center overflow-hidden">
                                    {images[curIndex].content}
                                </p>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div
                            className="absolute w-full h-full left-0 top-0 animate-swiper-backward"
                            key={`cur_${preIndex}`}
                        >
                            <GetImageVideoPlayer
                                enableZoom={false}
                                index={preIndex}
                                url={images[preIndex].url}
                            />
                            <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-end items-center text-2xl text-white pb-20 pointer-events-none">
                                <p>{images[preIndex].title}</p>
                                <p className="shadow-2xl my-4 w-3/4 text-ellipsis whitespace-nowrap text-center overflow-hidden">
                                    {images[preIndex].content}
                                </p>
                            </div>
                        </div>
                        <div
                            className="absolute w-full h-full -left-full top-0 animate-swiper1x-backward"
                            key={`pre_${curIndex}`}
                        >
                            <GetImageVideoPlayer
                                enableZoom={true}
                                index={curIndex}
                                url={images[curIndex].url}
                            />
                            <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col justify-end items-center text-2xl text-white pb-20 pointer-events-none">
                                <p>{images[curIndex].title}</p>
                                <p className="my-4 w-3/4 text-ellipsis whitespace-nowrap text-center overflow-hidden">
                                    {images[curIndex].content}
                                </p>
                            </div>
                        </div>
                    </>
                );
            }
        }
    }

    return (
        <div className="relative bg-white/60 w-full h-full rounded-2xl overflow-hidden">
            <GetImageContainer preIndex={preIndex} curIndex={curIndex} dir={playIndex.dir} />
            <div className="absolute w-1/6 h-full left-0 top-0 text-4xl text-white flex flex-row justify-center items-center">
                <div
                    className="w-16 h-16 flex flex-row justify-center items-center"
                    onClick={() => onClick(-1)}
                >
                    <p>{'<'}</p>
                </div>
            </div>
            <div className="absolute w-1/6 h-full right-0 top-0 text-4xl text-white flex flex-row justify-center items-center">
                <div
                    className="w-16 h-16 flex flex-row justify-center items-center"
                    onClick={() => onClick(-2)}
                >
                    <p>{'>'}</p>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full h-20 flex flex-row justify-center items-start pointer-events-none">
                {images.map((item, index) => {
                    if (index === curIndex) {
                        return (
                            <div
                                className="w-6 h-6 mx-2 flex flex-row justify-center items-center pointer-events-auto"
                                key={`indics-${index}`}
                                onClick={() => onClick(index)}
                            >
                                <div className="w-7 h-2 bg-white border border-c-c" />
                            </div>
                        );
                    } else {
                        return (
                            <div
                                className="w-6 h-6 mx-2 flex flex-row justify-center items-center pointer-events-auto"
                                key={`indics-${index}`}
                                onClick={() => onClick(index)}
                            >
                                <div className="w-7 h-2 bg-white/50 border border-c-c" />
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}
