import AutoZoomImage from '../../components/Carousel/auto_zoom_image';

function MainProductInfo() {
    return (
        <>
            <div className="relative mt-20" style={{ width: '100%', height: 0, paddingBottom: '30%' }}>
                <div className="absolute px-20 w-full h-full">
                    <div className="w-full h-full overflow-hidden">
                        <AutoZoomImage
                            enableZoom={true}
                            url="
                            https://site-1306369054.file.myqcloud.com/5322117680050072/PSjmFAfP7a%E5%8E%8B%E7%BC%A9%E6%9C%BA.jpg?imageMogr2/format/webp/cut/1700x531x0x519/4/format/webp/ignore-error/1"
                        />
                    </div>
                </div>
            </div>
            <div className="text-2xl my-20 indent-14 tracking-wide leading-relaxed" style={{ width: '80%' }}>
                <p>
                    今天（9日）下午3时，十四届全国人大二次会议举行记者会，教育部部长怀进鹏、人力资源和社会保障部部长王晓萍、住房和城乡建设部部长倪虹、国家疾病预防控制局局长王贺胜就教育、就业和社会保障、住房、医疗疾控等相关问题回答中外记者提问。
                    住建部部长倪虹表示，对于房地产，我们不能光看短期，还要看中长期。当前房地产市场在调整，稳定市场的任务依然艰巨。但从我国城镇化的发展进程看，从我国城镇300多亿平方米存量住房需要更新改造来看，房地产还是有很大的潜力和空间。
                    倪虹说，2023年，面对个别房企资金链断裂，房地产市场调整的风险挑战，在党中央国务院坚强领导下，我们会同有关部门出台了一系列政策措施，包括认房不认贷、降低贷款首付比例和利率、支持居民换购住房税费优惠、指导地方政府因城施策、抓好保交楼等，可以说守住了不发生系统性风险的底线。
                </p>
                <p>
                    <br />
                </p>
                <p>
                    据台湾联合新闻网3月6日报道，仅南投集集镇就在一周内发生两起军人轻生案。据台军方通报，陆军兵工发展整备中心（兵整中心）武化翻修厂的一名潘姓下士于5日晚8时许失联。家人与军方报案后，警方通过定位系统于6日早上在兵整中心附近一路口旁发现
                    潘的摩托车，随后在河边草地上找到他的尸体，颈部有明显刀伤。检方检验后称，现场并无他杀、外力介入致死嫌疑，确认为轻生案件。另一名家住南投集集的赵姓军人，上周五疑似为情所困，在集集拦河堰旁上吊。
                </p>
                <p>
                    <br />
                </p>
                <p>
                    联合新闻网7日称，台军8天内已发生5起自伤案。除了上述两起外，据台媒2月29日报道，台陆军第六军团指挥部所属542旅联兵一营战车第一连黄姓下士在家附近的车内轻生。接着，蔡英文办公室一名卫兵于3月1日晚间执勤时持枪自戕，
                    震惊岛内。3月2日台陆军航空第602旅的赵姓下士离家后未返家，当天晚上被发现陈尸在树林中。
                </p>
                <p>
                    <br />
                </p>
                <p>
                    台“国防部长”邱国正7日在“立法院”承认，台军自伤人员确实在增加，“有的人抗压性比较弱”，但军方不会去怪当事人，发生这种事总是很遗憾，对家庭、部队来说都会带来创伤。他以蔡英文办公室宪兵自戕案举例称，军方第一时间从官兵身心健康、
                    职务派遣有无违反规定等方面展开调查。按规定卫兵执勤2小时要歇6小时，该单位并没有违规。但岛内普遍认为，自伤案增加与台军士兵任务过重、压力过大有关。国民党“立委”徐巧芯7日说，据传自戕士兵已3天3夜没睡觉，但台军方却说“都很好”
                </p>
            </div>
        </>
    );
}

function ProductItemInfo({ productInfo }) {
    return (
        <div className="mb-20" style={{ width: '80%' }}>
            <div className="w-full h-full flex justify-center items-center py-10 bg-black/10">
                <div className="relative " style={{ width: '40%', height: 0, paddingBottom: '20%' }}>
                    <div className="absolute w-full h-full py-10">
                        <img className="w-full h-full" src={productInfo.img} />
                    </div>
                </div>
                <div style={{ width: '5%' }} />
                <div
                    className="text-2xl indent-14 tracking-wide leading-relaxed whitespace-pre-wrap"
                    style={{ width: '40%' }}
                >
                    <p>{productInfo.content}</p>
                </div>
            </div>
        </div>
    );
}

function ProductContent() {
    let productList = [
        {
            img: 'https://5322117680050072-1694683636837.cdn.site.joinf.com/5322117680050072/XBXekDSYPt.png?imageMogr2/format/webp/ignore-error/1',
            content: `当地时间3月8日，洪都拉斯前总统胡安·奥兰多·埃尔南德斯被美国纽约联邦法院的陪审团认定犯有贩毒罪，一旦法官宣判，埃尔南德斯可能会被判处终身监禁。\r\n\t\t美国纽约法院指控埃尔南德斯参与了“一场暴力贩毒阴谋”，将约50万公斤可卡因运往美国领土，同时指控其“为毒枭提供庇护，使其免于调查、逮捕和引渡”等罪名。2022年2月，他在首都的住所中被捕。同年4月被引渡到美国。洪都拉斯检察院2022年证实，\r\n\t\t埃尔南德斯将面临数十项指控，其中包括毒品犯罪、非法携带枪支入境等。胡安·奥兰多·埃尔南德斯2014至2022年担任洪都拉斯总统。`
        },
        {
            img: 'https://5322117680050072-1694683682350.cdn.site.joinf.com/5322117680050072/MnmpXmFCWw.png?imageMogr2/format/webp/ignore-error/1',
            content: `当地时间3月8日，洪都拉斯前总统胡安·奥兰多·埃尔南德斯被美国纽约联邦法院的陪审团认定犯有贩毒罪，一旦法官宣判，埃尔南德斯可能会被判处终身监禁。\r\n\t\t美国纽约法院指控埃尔南德斯参与了“一场暴力贩毒阴谋”，将约50万公斤可卡因运往美国领土，同时指控其“为毒枭提供庇护，使其免于调查、逮捕和引渡”等罪名。2022年2月，他在首都的住所中被捕。同年4月被引渡到美国。洪都拉斯检察院2022年证实，\r\n\t\t埃尔南德斯将面临数十项指控，其中包括毒品犯罪、非法携带枪支入境等。胡安·奥兰多·埃尔南德斯2014至2022年担任洪都拉斯总统。`
        },
        {
            img: 'https://5322117680050072-1694683729934.cdn.site.joinf.com/5322117680050072/rAiwbxy3A7.png?imageMogr2/format/webp/ignore-error/1',
            content: `当地时间3月8日，洪都拉斯前总统胡安·奥兰多·埃尔南德斯被美国纽约联邦法院的陪审团认定犯有贩毒罪，一旦法官宣判，埃尔南德斯可能会被判处终身监禁。\r\n\t\t美国纽约法院指控埃尔南德斯参与了“一场暴力贩毒阴谋”，将约50万公斤可卡因运往美国领土，同时指控其“为毒枭提供庇护，使其免于调查、逮捕和引渡”等罪名。2022年2月，他在首都的住所中被捕。同年4月被引渡到美国。洪都拉斯检察院2022年证实，\r\n\t\t埃尔南德斯将面临数十项指控，其中包括毒品犯罪、非法携带枪支入境等。胡安·奥兰多·埃尔南德斯2014至2022年担任洪都拉斯总统。`
        }
    ];
    return (
        <div className="w-full h-fit flex flex-col justify-start items-center">
            <MainProductInfo />
            <ProductItemInfo productInfo={productList[0]} />
            <ProductItemInfo productInfo={productList[1]} />
            <ProductItemInfo productInfo={productList[2]} />
        </div>
    );
}

export default ProductContent;
