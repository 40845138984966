import MaintenanceContent from './maintenancecontent';
import Header from '@/components/Header';
import Tailer from '@/components/Tailer';

function Maintenance() {
    console.log('------------- on Home() -------------------');
    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <MaintenanceContent />
            <Tailer />
        </div>
    );
}

export default Maintenance;
