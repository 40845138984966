import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Launch() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        onPageLoaded();
        return onPageUnLoaded;
    }, []);

    async function onPageLoaded() {
        console.log(`------------- on launch onPageLoaded path=${location.pathname}-------------------`);

        if (location.pathname === '/') {
            navigate('/home');
        }
    }

    //当主界面退出时时调用
    async function onPageUnLoaded() {
        console.log('------------- on launch onPageUnLoaded -------------------');
    }

    return (
        <div className={`w-screen h-screen text-24`}>
            <Outlet />
        </div>
    );
}

export default Launch;
