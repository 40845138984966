import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import TestDeviceImg from '@/assets/images/测井装备.png';
import TestDoorImg from '@/assets/images/阀门.png';
import ZipDeviceImg from '@/assets/images/离心式压缩机.png';
import BtnImg from '@/assets/images/按钮.png';

export default function ProductService() {
    const [posInfo, setPosInfo] = useState({
        curIndex: 0,
        nextIndex: 0
    });
    function onMouseEnter(index) {
        if (index !== posInfo.nextIndex) {
            console.log(
                `----onMouseEnter  index=${index} posInfo={${posInfo.curIndex}, ${posInfo.nextIndex}}}`
            );
            setPosInfo({
                curIndex: posInfo.nextIndex,
                nextIndex: index
            });
        }
    }
    function getFadeAnimateName(index) {
        console.log(`----getFadeAnimateName-----index=${index}`);
        switch (index) {
            case 0:
                console.log(`----getFadeAnimateName-----animate-fadeIn`);
                return 'animate-fadeIn';
            case 1:
                console.log(`----getFadeAnimateName-----animate-fadeIn1`);
                return 'animate-fadeIn1';
            default:
                console.log(`----getFadeAnimateName-----animate-fadeIn2`);
                return 'animate-fadeIn2';
        }
    }

    function onMouseLeave(index) {}

    function GetProductTextItem({ info }) {
        return (
            <div
                className={classNames(
                    'absolute w-full left-0',
                    info.index === posInfo.nextIndex
                        ? 'top-96 animate-moveup'
                        : info.index === posInfo.curIndex
                        ? 'top-120 animate-movedown'
                        : 'top-120'
                )}
            >
                <img className="ml-8 my-3 w-20" src={info.img} alt="" />
                <div className="mx-8 text-4xl text-white mt-6 mb-20 leading-normal">
                    <p>{info.content}</p>
                </div>
                <div className="ml-8 w-14 h-0.5 bg-white mb-6"></div>
                <img
                    className={classNames(
                        'ml-8 my-3 w-12',
                        info.index === posInfo.nextIndex
                            ? 'opacity-0 animate-moveinvisible'
                            : info.index === posInfo.curIndex
                            ? 'opacity-100 animate-movevisible'
                            : 'opacity-100'
                    )}
                    src={BtnImg}
                    alt=""
                />
                <div
                    className={classNames(
                        'ml-8 mt-16 w-28 h-10 border border-white rounded-2xl flex justify-center items-center',
                        info.index === posInfo.nextIndex
                            ? 'opacity-100 animate-movevisible'
                            : 'opacity-0 animate-moveinvisible'
                    )}
                >
                    <p className="text-white text-lg">了解更多</p>
                </div>
            </div>
        );
    }

    let imageList = [
        'https://site-1306369054.file.myqcloud.com/5322117680050072/GfemG7rrXJ%E4%BA%A7%E5%93%81%E8%83%8C%E6%99%AF%E5%9B%BE-%E5%B7%A6.jpg?imageMogr2/format/webp/ignore-error/1',
        'https://site-1306369054.file.myqcloud.com/5322117680050072/WnTpwPwmzy%E4%BA%A7%E5%93%81%E8%83%8C%E6%99%AF%E5%9B%BE-%E4%B8%AD.jpg?imageMogr2/format/webp/ignore-error/1',
        'https://site-1306369054.file.myqcloud.com/5322117680050072/ksi3MP67hQ%E4%BA%A7%E5%93%81%E8%83%8C%E6%99%AF%E5%9B%BE-%E5%8F%B3.jpg?imageMogr2/format/webp/ignore-error/1'
    ];
    return (
        <div className="relative w-full h-full rounded-2xl overflow-hidden">
            <div className="absolute w-full h-full left-0 top-0">
                <img className="w-full h-full" src={imageList[posInfo.curIndex]} />;
            </div>
            <div
                className={classNames(
                    'absolute w-full h-full left-0 top-0 animate-fadeIn',
                    posInfo.curIndex !== posInfo.nextIndex ? getFadeAnimateName(posInfo.nextIndex) : ''
                )}
            >
                <img className="w-full h-full" src={imageList[posInfo.nextIndex]} />;
            </div>
            <div
                className={classNames('absolute h-full', posInfo.nextIndex === 0 ? 'bg-black/60' : '')}
                style={{ top: 0, left: '10%', width: '24%' }}
                onMouseEnter={() => {
                    onMouseEnter(0);
                }}
                onMouseLeave={() => {
                    onMouseLeave(0);
                }}
            >
                <GetProductTextItem
                    info={{ index: 0, img: TestDeviceImg, content: '撤并设备功能介绍，准备测试标题' }}
                />
            </div>
            <div
                className={classNames('absolute h-full', posInfo.nextIndex === 1 ? 'bg-black/60' : '')}
                style={{ top: 0, left: '34%', width: '40%' }}
                onMouseEnter={() => {
                    onMouseEnter(1);
                }}
                onMouseLeave={() => {
                    onMouseLeave(1);
                }}
            >
                <GetProductTextItem
                    info={{
                        index: 1,
                        img: TestDoorImg,
                        content: '阀门设备功能介绍，压缩机阀门设备，最新功能测试，准备测试标题'
                    }}
                />
            </div>
            <div
                className={classNames('absolute h-full', posInfo.nextIndex === 2 ? 'bg-black/60' : '')}
                style={{ top: 0, left: '74%', width: '16%' }}
                onMouseEnter={() => {
                    onMouseEnter(2);
                }}
                onMouseLeave={() => {
                    onMouseLeave(2);
                }}
            >
                <GetProductTextItem
                    info={{ index: 2, img: ZipDeviceImg, content: '离心式压缩机设备功能介绍，准备测试标题' }}
                />
            </div>
            <div className="absolute h-full w-px bg-white/30" style={{ top: 0, left: '10%' }}></div>
            <div className="absolute h-full w-px bg-white/30" style={{ top: 0, left: '34%' }}></div>
            <div className="absolute h-full w-px bg-white/30" style={{ top: 0, left: '74%' }}></div>
            <div className="absolute h-full w-px bg-white/30" style={{ top: 0, left: '90%' }}></div>
        </div>
    );
}
