import AutoZoomImage from '../../components/Carousel/auto_zoom_image';

export default function ContactContent() {
    function ContactImgInfo() {
        return (
            <div className="relative mt-20" style={{ width: '100%', height: 0, paddingBottom: '30%' }}>
                <div className="absolute px-20 w-full h-full">
                    <div className="w-full h-full overflow-hidden">
                        <AutoZoomImage
                            enableZoom={false}
                            url="https://site-1306369054.file.myqcloud.com/5322117680050072/BtmpkAhTmM.png?imageMogr2/format/webp/ignore-error/1"
                        />
                    </div>
                </div>
            </div>
        );
    }

    function ContactInfo() {
        return (
            <div className="w-4/5 flex my-20 justify-center items-start">
                <div className="flex flex-col">
                    <p className="text-3xl">我们随时欢迎您的到来</p>
                    <p className="text-5xl my-20">因为我们是朋友</p>
                    <p className="text-2xl  mb-6">联系地址：北京市朝阳区十八里店</p>
                    <p className="text-2xl  mb-6">联系人：温先生</p>
                    <p className="text-2xl  mb-6">手机号：138xxxx543</p>
                    <p className="text-2xl">邮箱：wenshaokai@163.com</p>
                </div>
                <div className="w-1/6" />
                <img
                    className="w-2/5"
                    src="https://aohuaresource.oss-cn-beijing.aliyuncs.com/hhweb/images/address.png"
                ></img>
            </div>
        );
    }

    return (
        <div className="w-full h-fit flex flex-col justify-start items-center">
            <ContactImgInfo />
            <ContactInfo />
        </div>
    );
}
