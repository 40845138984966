
function MaintenanceContent() {
    return (
        <div className="w-full h-content flex flex-row justify-start items-start">
            Maintenance
        </div>
    );
}

export default MaintenanceContent;
